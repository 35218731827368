import React from 'react';

import { TextField, Grid, Button, Typography, Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    fontSize: "16px",
    marginBottom: '2em',
    lineHeight: '1.1',
    '& strong': {
      textTransform: 'uppercase',
      color: '#793478',
      fontSize: '20px'
    }
  },
  alternate: {
    fontSize: '16px',
    '& strong': {
      textTransform: 'uppercase',
      color: '#793478'
    },
    lineHeight: '1.1',
  },
  caption: {
    fontSize: '12px'
  }
});


const Welcome = (props) => {

  const classes = useStyles();

  return (
    <Grid item>
      <Typography variant="body2"  className={classes.root} gutterBottom>

      WELCOME TO FESTIVALE 2025 STALLHOLDER APPLICATIONS<br /><br />
 
 If you have previously applied as a stallholder, please login using your login details from last year. <br /><br />
  
 If you have not previously applied, please click Register to create a new account or click forgotten password.<br /><br />
  
 For more information about applying to be a 2025 Festivale stallholder please view the <a href="/Stallholder information guide 2025_ FINAL.pdf" target="_blank">Festivale Stallholder Information Guide</a>.<br /><br />
 
 Please fill in the application form to the best of your ability and if you have any queries please do not hesitate to contact our event manager Hayley Young at eventmanager@festivale.com.au<br /><br />
  
 Applications close 26 July 2024, 5:00pm
 
      </Typography>
    </Grid>
  );
}

export default Welcome;
