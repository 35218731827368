const xmlData = `

<?xml version="1.0" encoding="UTF-8" ?>
<app>
    <step id="welcome" name="Welcome">
      <group name="Important information you need to know before you start" className="reversed">
        <display hideInPDF="yes" />

        <question id="info_01">
          <name>Please be sure to save content as you go through the Stallholder Application Form process – your information won't be saved unless you hit save at the bottom of each page before you exit. </name>
          <config type="info" />
          <display cols="12"  />

        </question>
        <question id="info_02">
          <name>At the top of each page, view how many tasks you need to complete before you finalise and submit your application.</name>
          <config type="info" />
          <display cols="12"  />
        </question>
        <question id="info_03">
          <name>All information you supply will be used as part of our Stallholder evaluation process. </name>
          <config type="info" />
          <display cols="12"  />
        </question>

      </group>
      <group name="Chairmans Welcome">
        <question id="chairman" name="chairman">
          <config type="info" />
          <display cols="12" />
        </question>

        <question name="Welcome from Chairman acknowledgement" id="inital_agree2025">
          <config type="check" />
          <option value="yes" name="I have read the welcome from the Chairman and now wish to continue" id="agree" />
          <validator id="isRequired" />
        </question>

        <question name="helper" id="info_guide">
          <config type="info" />
        </question>

      </group>
    </step>
    <step id="stall" name="Stall">
        <group name="Welcome - let's get started">
            <question id="previous_stallholder">
              <name>Have you been a stallholder at Festivale before?</name>
              <config type="radio" />
              <display cols="12" />

              <validator id="isRequired" />

              <option id="yes" name="Yes" value="yes">
                <description>We've been a stallholder previously.</description>
              </option>
              <option id="no" name="No" value="no">
                <description>This is our first time.</description>
              </option>
            </question>


            <question id="meet_manager" name="Meeting the Event Manger">
              <config type="check" />
              <validator id="isRequired" />
              <option id="yes" name="Yes, as this is my first Festivale, I'm happy to meet with the Event Manager as part of the process" value="yes" />
              <condition
                target=".stall.previous_stallholder"
                validator="isEqual"
                value="no"
              />
            </question>

            <!-- group id="prev_stallholder_extra_info">
              <condition
                target=".stall.previous_stallholder"
                validator="isEqual"
                value="no" />

              <question id="previous_stallholder_info" >
                <name>Please provide the details of two trades references or previous events references that you're happy for Festivale to contact when considering your application</name>
                <config type="info" />
                <display cols="12" />
              </question>

              <question id="previous_reference_name" name="Reference 1 Name">
                <config type="text" />
                <display cols="3" />
                <validator id="isRequired" />
              </question>

              <question id="previous_reference_contact" name="Reference 1 Phone or Email">
                <config type="text" />
                <display cols="3" />
                <validator id="isRequired" />
              </question>

              <question id="previous_reference_details" name="Details about this reference">
                <config type="text" />
                <display cols="6" multiline="4" />
                <validator id="isRequired" />
              </question>

              <question id="previous_reference_name2" name="Reference 2 Name">
                <config type="text" />
                <display cols="3" />
                <validator id="isRequired" />
              </question>

              <question id="previous_reference_contact2" name="Reference 2 Phone or Email">
                <config type="text" />
                <display cols="3" />
                <validator id="isRequired" />
              </question>

              <question id="previous_reference_details2" name="Details about this reference">
                <config type="text" />
                <display cols="6" multiline="4" />
                <validator id="isRequired" />
              </question>

            </group -->
        </group>

        <group name="About your stall">
        <description>Please be aware that for all co-branded sites, only one user can be logged into the same application simultaneously. This is to prevent any loss of information within the application.</description>
          <question id="co_branded" name="Is your site Co-branded? (please select one) ">

            <config type="radio" />
            <display cols="8" />

            <validator id="isRequired" />

            <option id="no" name="No, I'm a single stallholder only" value="no" />
            <option id="yes2" name="Yes, I’m Co-branding with another stallholder " value="yes2">
              <description>This option applies to a maximum of two (2) businesses requesting to share a 6m x 6m or 6m x 3m space. You cannot share a stall with a like for like business ie. two wine businesses sharing or two food businesses sharing.</description>
            </option>
            <option id="yes3" name="Yes, I’m a shared food and beverage site but will showcase one brand only" value="yes3">
              <description>
                A maximum of three (3) businesses of any type can share a site under one common name. The stall will be promoted as a collective and will operate/trade under one common name.
              </description>
            </option>
          </question>

          <question name="Number of businesses sharing" id="numSharing">
            <config type="select" />
            <validator id="isRequired" />
            <condition
              target=".stall.co_branded"
              validator="isEqual"
              value="yes3"
            />
            <option id="two" name="2" value="2" />
            <option id="three" name="3" value="3" />
          </question>

          <question id="stall_name" name="Primary stallholder name">
            <config type="text" />
            <help_text>Enter your stall name as it will appear in the official program and website.</help_text>
            <display cols="12" />
            <validator id="isRequired" />
          </question>

          <question id="stall_name2" name="Secondary stallholder name ">
            <config type="text" />
            
            <display cols="12" />
            <validator id="isRequired" />
            <condition
              target=".stall.co_branded"
              validator="isEqual"
              value="yes2"
            />
          </question>

          <Group>
              <display cols="12" hideInPDF="yes"  />

                    <question id="stall_desc_desc">
                      <name>Please provide an overview of your stall. This information will assist the Festivale committee in evaluating your stallholder application and may also be used to showcase your stall on the Festivale website.</name>
                        <config type="info" />

                    </question>
          </Group>
          <question id="stall_description" name="Site Description">
            <config type="text" />
            
            <help_text></help_text>
            <display cols="6" multiline="4" word_counter="50" placeholder="Please supply a concise description of the products available at your stall. This description will appear on our website and app listings alongside your stall name, aiding patrons in anticipating what they can expect to find when they visit your site." />
            <validator id="isRequired" />
            <validator id="hasWordLimit">
              <config limit="50" />
            </validator>
          </question>

          <question id="site_type" name="Let us know what your stall will be offering ">
            <config type="radio" />
            <display cols="4" />
            <validator id="isRequired" />
            <option id="food" name="Food Only" value="food" description="Includes non-alcoholic drinks, water and soft drink.">
              <condition
                target=".stall.co_branded"
                validator="isNotEqual"
                value="yes2"
              />
            </option>
            <option id="beverage" name="Beverage Only" value="beverage" >

            </option>
            <option id="both" name="Food &amp; Beverage" value="both" description="Food and alcholic beverages." />
            <option id="coffee" name="Coffee Van" value="coffee" description="">
              <condition
                target=".stall.co_branded"
                validator="isEqual"
                value="no"
              />
            </option>
          </question>
        </group>

        <group name="What will your stall look like?">
          <description>
           Let us know about your stall - Describe the physical appearance of your stall and how you intend to attract patrons. &lt;br/&gt;&lt;br/&gt;

             Here are a few examples of what previous stallholders did to stand out from the crowd and appeal to patrons. &lt;br/&gt;

            </description>
          
          <group>
              <display  hideInPDF="yes"  />
          <question name="examples" id="examples">
            <config type="info" />
            <display cols="12" />
          </question>
          </group>

          <question id="presentation" name="How will your stall look?">
            <config type="text" />
            <help_text></help_text>
            <display multiline="4" word_counter="200" />
            <validator id="hasWordLimit">
              <config limit="200" />
            </validator>
            <validator id="isRequired" />
          </question>

          <question id="tasmanian" name="What is uniquely Tasmanian about your product/stall?">
            <config type="text" />
            <help_text>Please tell us what is uniquely Tasmanian and/or innovative about your product and what you will do to make your product and stall stand out at Festivale (limit 200 words).</help_text>
            <display multiline="4" word_counter="200" />
            <validator id="hasWordLimit">
              <config limit="200" />
            </validator>
            <validator id="isRequired" />
          </question>

          <question id="photoDesc" name="Please upload max of 3 images of your stall design to support your application.">
            <config type="info" />
            <display cols="12" />
          </question>

          <question id="photo_01" name="Photo One">
            <config type="file" />
            <display cols="4" />
            <validator id="isRequired" />
          </question>

          <question id="photo_02" name="Photo Two">
            <config type="file" />
            <display cols="4" />
          </question>

          <question id="photo_03" name="Photo Three">
            <config type="file" />
            <display cols="4" />
          </question>

        </group>

        <group name="Temporary Food Business" id="more">
        <description>Festivale and the City of Launceston take food and beverage hygiene very seriously.  If you are successful, ALL food and ALL beverage operators will be required to have a current Temporary Food Business (TFB) permit (statewide or City of Launceston). &lt;br/&gt;&lt;br/&gt;
          If you do not have a permit, you will be required to complete TFB application and submit to the City of Launceston.  &lt;br/&gt;&lt;br/&gt;
          A free food safety training course is available and recommended for all food handlers.  For more visit &lt;a href='https://www.launceston.tas.gov.au/Community/Health-and-Safety/Food-Safety' target='_blank'&gt;City of Launceston - Food Safety&lt;/a&gt;
        </description>
         


<group>

        <question name="NOTE: If you are successful, all food operators will be required to have a current Temporary Food Business (TFB) permit (statewide or City of Launceston). If you do not have a permit, you will be required to complete TFB application and submit to the City of Launceston. A copy of your registration will be requested by the Environmental Health Department of the City of Launceston." id="extra_ticket_info">
          <config type="info" />
          <display cols="12" />
        </question>

          <!--question id="temporary_permit" name="Temporary Food Business permit">
            <config type="radio" />
            <option value="yes" name="Yes" description="I have a current Temporary Food Business permit." />
            <option value="no" name="No" description="I do not have a current Temporary Food Business permit." />
            <option value="not_required" name="I do not require a Temporary Food Business permit" />
          </question-->

          <question id="temporary_premit_acknowledge" name="Temporary food business permit for Festivale">
            <config type="check" />
            <option value="yes" name="I acknowledge that I am required to obtain a temporary food business permit for Festivale " id="agree" />
            <validator id="isRequired" />
          </question>

          <!-- question id="permit_date" name="Permit Expiration date">
            <config type="date" />
            <condition
                target=".business.temporary_permit"
                validator="isEqual"
                value="yes" />
          </question -->
      </group>
  </group>
  <group name="Resposible service of alcohol" id="rsa">
           
              <condition
                target=".stall.site_type"
                validator="isNotEqual"
                value="food"
              />
    


          <question id="rsa" name="Do you and all staff working at your stall hold an RSA Certificate?">
          <help_text>It is a requirement by Festivale that ALL staff serving alcohol at the event have undertaken the Responsible Serving of Alcohol Course. Staff working at the event will be required to provide a copy of their RSA's to the Festivale office prior to bump in. Festivale, Liquor and Gaming Licensing Branch and Tasmania Police will be conducting random checks throughout the event to ensure compliance.&lt;br&gt; FAILURE TO COMPLY MAY RESULT IN PROSECUTION BY THE LICENSING COMMISSION. </help_text>
            <config type="radio" />
            <option id="yes" name="Yes" value="yes" />
            <option id="no" name="No" value="no" />
            <condition
              target=".stall.site_type"
              validator="isNotEqual"
              value="food"
            />
          </question>

          
         


        </group>

        <group name="Masterclass" id="mc_group">
           
          <condition
            target=".stall.site_type"
            validator="isNotEqual"
            value="food"
          />

          <question id="masterclass_consideration" name="Masterclass Consideration">
            <config type="check" />
            <option id="yes" name="Yes I would like to be considered for involvement with a Masterclass" value="yes" />
            <condition
              target=".stall.site_type"
              validator="isNotEqual"
              value="food"
            />
            <help_text>For more information regarding Masterclsses please visit  &lt;a href="https://festivale.com.au/event-experience/masterclasses/" target="_blank"&gt;our website&lt;/a&gt;.</help_text>
          </question>


        </group>


        <group id="reserved_bar" name="Reserved Seating Bar">
        <condition
            target=".stall.site_type"
            validator="isNotEqual"
            value="food"
          />
          <description>
            Are you a beverage stallholder? Would you like to see your product sold from the reserved seating bar at Festivale? &lt;br/&gt;
            The bar will be serving into the reserved seating area A marquee with a capacity of 250 patrons.&lt;br/&gt;&lt;br/&gt;
            
            Considerations:&lt;br/&gt;
            1.	Beverages will be purchased from stallholders at wholesale prices.&lt;br/&gt;
            2.	If you agree, you must have sufficient stock available for all three days of the event.&lt;br/&gt;
            3.	You will not need to staff the bar; only providing the product is required.&lt;br/&gt;
          </description>
         
        <question id="consideration" name="Reserved Seating Bar Consideration">
          <config type="check" />
          <option id="yes" name="Yes I would like to be considered for my products to be sold from the reserved seating bar" value="yes" />
        </question>
         
        </group>
    </step>

    <step id="personal" name="Personal">
      <group name="Primary Applicant">

        <question id="name_first" name="First Name">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="name_last" name="Last Name">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="position" name="Position">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="mobile" name="Contact Number">
          <config type="text" />
          <display>
            <numberFormat format="#### ### ###" allowEmptyFormatting="true" mask="_" />
          </display>
          <validator id="isRequired" />
        </question>

        <question id="address" name="Street Address or PO Box">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="suburb" name="Suburb">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="state" name="State">
          <config type="select" />
          <validator id="isRequired" />
          <option id="tas" name="Tasmania" />
          <option id="vic" name="Victoria" />
          <option id="nsw" name="New South Wales" />
          <option id="sa" name="South Australia"/>
          <option id="act" name="Australian Captial Territory"/>
          <option id="qld" name="Queensland"/>
          <option id="wa" name="Western Australia"/>
          <option id="nt" name="Nothern Territory"/>
        </question>

        <question id="postcode" name="Postcode">
          <config type="text" />
          <validator id="isRequired" />
          <display>
            <numberFormat format="####" allowEmptyFormatting="true" mask="_" />
          </display>

        </question>

        <question id="email" name="Email Address">
          <config type="text" />
          <validator id="isRequired" />
          <validator id="isEmail">
            <config message="must be a valid email address" />
          </validator>
        </question>

        <question id="website" name="Website Address">
          <config type="text" />
        </question>

        <question id="social" name="Social Network Links">
          <config type="text" />
          <display multiline="4" />
        </question>

      </group>

      <group name="Optional Authorised Secondary Contact" id="secondary">
        <question id="name_first" name="First Name">
          <config type="text" />
        </question>

        <question id="name_last" name="Last Name">
          <config type="text" />
        </question>

        <question id="position" name="Position">
          <config type="text" />
        </question>

        <question id="email" name="Email Address">
          <config type="text" />
          <!-- validator id="isEmail">
            <config message="must be a valid email address" />
          </validator -->
        </question>

      </group>


      <group name="Co-brand Applicant">
        <condition
          target=".stall.co_branded"
          validator="isEqual"
          value="yes2" />

        <question id="name_first.secondary" name="First Name">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="name_last.secondary" name="Last Name">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="position.secondary" name="Position">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="mobile.secondary" name="Contact Number">
          <config type="text" />
          <display>
            <numberFormat format="#### ### ###" allowEmptyFormatting="true" mask="_" />
          </display>
          <validator id="isRequired" />
        </question>

        <question id="address.secondary" name="Street Address or PO Box">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="suburb.secondary" name="Suburb">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="state.secondary" name="State">
          <config type="select" />
          <validator id="isRequired" />
          <option id="tas" name="Tasmania" />
          <option id="vic" name="Victoria" />
          <option id="nsw" name="New South Wales" />
          <option id="sa" name="South Australia"/>
          <option id="act" name="Australian Captial Territory"/>
          <option id="qld" name="Queensland"/>
          <option id="wa" name="Western Australia"/>
          <option id="nt" name="Nothern Territory"/>
        </question>

        <question id="postcode.secondary" name="Postcode">
          <config type="text" />
          <validator id="isRequired" />
          <display>
            <numberFormat format="####" allowEmptyFormatting="true" mask="_" />
          </display>
        </question>

        <question id="email.secondary" name="Email Address">
          <config type="text" />
          <validator id="isRequired" />
          <validator id="isEmail">
            <config message="must be a valid email address" />
          </validator>
        </question>

        <question id="website.secondary" name="Website Address">
          <config type="text" />
        </question>

        <question id="social.secondary" name="Social Network Links">
          <config type="text" />
          <display multiline="4" />
        </question>

      </group>

      <group name="Second Shared Applicant">
        <condition
          target=".stall.co_branded"
          validator="isEqual"
          value="yes3" />

        <question id="name_first.secondary" name="First Name">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="name_last.secondary" name="Last Name">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="position.secondary" name="Position">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="mobile.secondary" name="Contact Number">
          <config type="text" />
          <display>
            <numberFormat format="#### ### ###" allowEmptyFormatting="true" mask="_" />
          </display>
          <validator id="isRequired" />
        </question>

        <question id="address.secondary" name="Street Address or PO Box">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="suburb.secondary" name="Suburb">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="state.secondary" name="State">
          <config type="select" />
          <validator id="isRequired" />
          <option id="tas" name="Tasmania" />
          <option id="vic" name="Victoria" />
          <option id="nsw" name="New South Wales" />
          <option id="sa" name="South Australia"/>
          <option id="act" name="Australian Captial Territory"/>
          <option id="qld" name="Queensland"/>
          <option id="wa" name="Western Australia"/>
          <option id="nt" name="Nothern Territory"/>
        </question>

        <question id="postcode.secondary" name="Postcode">
          <config type="text" />
          <validator id="isRequired" />
          <display>
            <numberFormat format="####" allowEmptyFormatting="true" mask="_" />
          </display>
        </question>

        <question id="email.secondary" name="Email Address">
          <config type="text" />
          <validator id="isRequired" />
          <validator id="isEmail">
            <config message="must be a valid email address" />
          </validator>
        </question>

        <question id="website.secondary" name="Website Address">
          <config type="text" />
        </question>

        <question id="social.secondary" name="Social Network Links">
          <config type="text" />
          <display multiline="4" />
        </question>

      </group>

      <group name="Third Shared Applicant (if applicable)">
        <condition
          target=".stall.numSharing"
          validator="isEqual"
          value="3" />
          <condition
            target=".stall.co_branded"
            validator="isEqual"
            value="yes3" />

        <question id="name_first.tertiary" name="First Name">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="name_last.tertiary" name="Last Name">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="position.tertiary" name="Position">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="mobile.tertiary" name="Contact Number">
          <config type="text" />
          <display>
            <numberFormat format="#### ### ###" allowEmptyFormatting="true" mask="_" />
          </display>
          <validator id="isRequired" />
        </question>

        <question id="address.tertiary" name="Street Address or PO Box">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="suburb.tertiary" name="Suburb">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="state.tertiary" name="State">
          <config type="select" />
          <validator id="isRequired" />
          <option id="tas" name="Tasmania" />
          <option id="vic" name="Victoria" />
          <option id="nsw" name="New South Wales" />
          <option id="sa" name="South Australia"/>
          <option id="act" name="Australian Captial Territory"/>
          <option id="qld" name="Queensland"/>
          <option id="wa" name="Western Australia"/>
          <option id="nt" name="Nothern Territory"/>
        </question>

        <question id="postcode.tertiary" name="Postcode">
          <config type="text" />
          <validator id="isRequired" />
          <display>
            <numberFormat format="####" allowEmptyFormatting="true" mask="_" />
          </display>
        </question>

        <question id="email.tertiary" name="Email Address">
          <config type="text" />
          <validator id="isRequired" />
          <validator id="isEmail">
            <config message="must be a valid email address" />
          </validator>
        </question>

        <question id="website.tertiary" name="Website Address">
          <config type="text" />
        </question>

        <question id="social.tertiary" name="Social Network Links">
          <config type="text" />
          <display multiline="4" />
        </question>

      </group>

    </step>

    <step id="site" name="Site">
      <group name="Site Structure">
        <question id="type" name="Site Type">
          <config type="select" />
          <display cols="12" />
          <help_text>
            Note: All vans and caravans will be required to bump in early.Please specify your required site frontage and depth.You must include any storage items(refrigerated trailer, preparation area, cooking area, tow bar, tables, doors etc for the entire area e.g. 6m x 6m footprint). Please ensure you round up your site dimensions, your entire footprint must fit within the site.i.e.If your site size is 4.8m then need to select 6m.Further Note: Any stallholder requiring a marquee must use a Festivale supplied marquee, but you must supply your own impervious flooring(to not allow any fluid to pass through)
          </help_text>
          <validator id="isRequired" />
          <option id="caravan" name="Caravan/Van" value="caravan">
          <condition
            target=".stall.co_branded"
            validator="isNotEqual"
            value="yes2"
          />

          </option>
          <option id="marquee" name="Marquee (provided as part of site fee)" value="marquee">
            <condition
              validator="isNotEqual"
              target=".stall.site_type"
              value="coffee"
            />
          </option>
        </question>

        

        <question id="frontage" name="Site Frontage (including tow bar)">
          <config type="select" />
          <display cols="12" />
          <option id="3" value="3.0" name="3.0m">
            <condition
              target=".stall.co_branded"
              validator="isNotEqual"
              value="yes2"
            />
          </option>
          <option id="6" value="6.0" name="6.0m" />
        </question>



        <question id="depth" name="Site Depth">
          <config type="select" />
          <display cols="12" />
          <help_text>Note: The true dimensions of your site are important for the layout of the event. Your information must be accurately depicted in your site diagram and include all intended areas to be used including cooking areas,
          washing up stations, storage facilities, cold rooms, preparations areas, doors and tow bars (if using a van or caravan) as the entire measurement. Please include in red the exact locations of gas cylinders on your site plan. 
          Any additional areas not included within your application are not permitted.</help_text>
          <option id="3" value="3.0" name="3.0m" />
          <option id="6" value="6.0" name="6.0m">
            <condition
              target=".stall.site_type"
              validator="isNotEqual"
              value="beverage"
            />
          </option>
        </question>

        <question id="additional_marqee" name="Do you require additional space behind your site for equipment such as fridges, freezers and or tables?">
          <help_text>Please note this will be an additional cost as these will be required to sit under a marquee and are subject to availability. If you do not select this option, you will not be permitted to have any equipment outside of your van.</help_text>
          <config type="radio" />
          <display cols="12" />
          <option id="yes" value="yes" name="Yes" />
          <option id="no" value="no" name="No" />
          <condition
              target=".site.type"
              validator="isEqual"
              value="caravan"
            />
        </question>

        <question id="mobile_cooler" name="Would you like to bring a mobile cooler van? If Yes, please do not include this in your site footprint above. These spaces are limited and are subject to stall location and size. You will be notified by the Festivale Event Manager if you are able to bring your cooler van once the site plan has been completed.">
          <config type="radio" />
          <display cols="12" />
          <option id="yes" value="yes" name="Yes" />
          <option id="no" value="no" name="No" />
        </question>




</group>


      <group name="Site Requirements">
        <description>Please list all equipment/furniture that you will be using on your site and upload a drawing of your site layout/map, including location of gas bottles.</description>
        <question id="equipment" name="It's important that you detail the equipment you will be bringing to Festivale no matter what size e.g. bbqs, deep fryer, refrigerated cabinets, made to order benches, bars, or signage.">
        <config type="text" />
          <display cols="12" multiline="12" />

        </question>
    <group><display  hideInPDF="yes"  />
        <question id="layoutinfo" name="layout info">
          <config type="info" />
          <display cols="12" />
        </question>
        </group>

        <question id="plan" name="Your site layout/map">
          <config type="file" />
          <help_text>You MUST upload your Site Layout/Map here (pdf/jpg).</help_text>
          <validator id="isRequired" />
        </question>

      </group>

      <group name="Power Requirement" id="power">
        <description>Your site fee includes 1x 15amp power connection. Additonal power outlets are $190 ( inc GST )</description>

        <question id="outlets" name="How many additional 15 amp outlets do you require? " >
          <config type="slider" min="0" max="5" />
        </question>

        <question id="power_info" >
          <name>
            Please see below an example of typical wattages to assist you with calculating your power requirements.
            </name>
          <config type="info" />
          <display cols="12" />
        </question>
        <question id="power_info2" >
          <name>
        </name>
          <config type="info" />
          <display cols="12" />
        </question>



      </group>

      <group name="Gas Requirement" id="gas">
        <description> Gas $230 (inc GST) – one off cost, includes all gas required for the event. Available in 8.5kg, 15kg or 45kg bottles.</description>

         <question id="required" name="Will your stall required gas">
            <config type="radio" />
            <validator id="isRequired" />

              <option id="yes" name="Yes" value="yes">
              </option>
              <option id="no" name="No" value="no">
              </option>
          </question>


    <group>
        <condition
              target=".site.gas.required"
              validator="isEqual"
              value="yes"
            />

        <question id="gas_fri_85" name="Friday - Total 8.5kg gas bottles needed">
               
          <config type="select" />
          <display cols="4" />
          <option id="none" name="None" value="0" />
          <option id="1" name="1" value="1" />
          <option id="2" name="2" value="2" />
          <option id="3" name="3" value="3" />
          <option id="4" name="4" value="4" />
          <option id="5" name="5" value="5" />
          <option id="6" name="6" value="6" />
        </question>

        <question id="gas_sat_85" name="Saturday - Total 8.5kg gas bottles needed">
          <config type="select" />
          <display cols="4" />
          <option id="none" name="None" value="0" />
          <option id="1" name="1" value="1" />
          <option id="2" name="2" value="2" />
          <option id="3" name="3" value="3" />
          <option id="4" name="4" value="4" />
          <option id="5" name="5" value="5" />
          <option id="6" name="6" value="6" />
        </question>

        <question id="gas_sun_85" name="Sunday - Total 8.5kg gas bottles needed">
          <config type="select" />
          <display cols="4" />
          <option id="none" name="None" value="0" />
          <option id="1" name="1" value="1" />
          <option id="2" name="2" value="2" />
          <option id="3" name="3" value="3" />
          <option id="4" name="4" value="4" />
          <option id="5" name="5" value="5" />
          <option id="6" name="6" value="6" />
        </question>

        <question id="gas_fri_15" name="Friday - Total 15kg gas bottles needed">
          <config type="select" />
          <display cols="4" />
          <option id="none" name="None" value="0" />
          <option id="1" name="1" value="1" />
          <option id="2" name="2" value="2" />
          <option id="3" name="3" value="3" />
          <option id="4" name="4" value="4" />
          <option id="5" name="5" value="5" />
          <option id="6" name="6" value="6" />
        </question>

        <question id="gas_sat_15" name="Saturday - Total 15kg gas bottles needed">
          <config type="select" />
          <display cols="4" />
          <option id="none" name="None" value="0" />
          <option id="1" name="1" value="1" />
          <option id="2" name="2" value="2" />
          <option id="3" name="3" value="3" />
          <option id="4" name="4" value="4" />
          <option id="5" name="5" value="5" />
          <option id="6" name="6" value="6" />
        </question>

        <question id="gas_sun_15" name="Sunday - Total 15kg gas bottles needed">
          <config type="select" />
          <display cols="4" />
          <option id="none" name="None" value="0" />
          <option id="1" name="1" value="1" />
          <option id="2" name="2" value="2" />
          <option id="3" name="3" value="3" />
          <option id="4" name="4" value="4" />
          <option id="5" name="5" value="5" />
          <option id="6" name="6" value="6" />
        </question>

         <question id="gas_fri_45" name="Friday - Total 45kg gas bottles needed">
          <config type="select" />
          <display cols="4" />
          <option id="none" name="None" value="0" />
          <option id="1" name="1" value="1" />
          <option id="2" name="2" value="2" />
          <option id="3" name="3" value="3" />
          <option id="4" name="4" value="4" />
          <option id="5" name="5" value="5" />
          <option id="6" name="6" value="6" />
        </question>

        <question id="gas_sat_45" name="Saturday - Total 45kg gas bottles needed">
          <config type="select" />
          <display cols="4" />
          <option id="none" name="None" value="0" />
          <option id="1" name="1" value="1" />
          <option id="2" name="2" value="2" />
          <option id="3" name="3" value="3" />
          <option id="4" name="4" value="4" />
          <option id="5" name="5" value="5" />
          <option id="6" name="6" value="6" />
        </question>

        <question id="gas_sun_45" name="Sunday - Total 45kg gas bottles needed">
          <config type="select" />
          <display cols="4" />
          <option id="none" name="None" value="0" />
          <option id="1" name="1" value="1" />
          <option id="2" name="2" value="2" />
          <option id="3" name="3" value="3" />
          <option id="4" name="4" value="4" />
          <option id="5" name="5" value="5" />
          <option id="6" name="6" value="6" />
        </question>


    </group>
    </group>


      <group name="Fridge/ Freezer space" id="freezer">
        <description></description>

        <question id="require_freezer" name="Will you require space in our Refrigerated fridges or freezers on site?">
           <help_text>please note each stall can book a maximum of 1x1m square and all stalls must still have refrigeration at their stalls. </help_text>
           <config type="radio" />
           <validator id="isRequired" />

             <option id="yes" name="Yes" value="yes">
             </option>
             <option id="no" name="No" value="no">
             </option>
        </question>
    </group>



    </step>

    <step id="business" name="Business">
      <group name="Business Details">
        <question id="abn" name="ABN of primary applicant">
          <config type="text" />
          <display cols="12">
            <numberFormat format="## ### ### ###" allowEmptyFormatting="true" mask="_" />
          </display>
        </question>

        <question id="business_category" name="Business Category ">
          <config type="select" />
          <validator id="isRequired" />
          <option name="Brewer" />
          <option name="Restaurant" />
          <option name="Caterer" />
          <option name="Food Van" />
          <option name="Winery" />
          <option name="Distiller" />
          <option name="Primary Producer" />
          <option name="Other" value="other" />
        </question>

        <question id="business_category_other" name="Please provide details">
          <config type="text" />
          <validator id="isRequired" />
          <condition
              target=".business.business_category"
              validator="isEqual"
              value="other" />
        </question>
      </group>
      
    </step>

    

    <step id="menu" name="Menu">
      <group name="Menu Items"  layout="MenuRepeater">
        <description>
          Please let us know what items you will have on your menu at Festivale.&lt;br/&gt;&lt;br/&gt;

          We recommend no more than 4 offerings on your menu, with the addition of tasting sizes and platter combinations. Tasting size options for your menu items are recommended and encouraged and are one of our stall award categories. To be eligible for this award your tasting plate must be a sample of your offering, not a half serve of one of the menu items.&lt;br/&gt;&lt;br/&gt;
          There must be at least one food item on your menu for $10 or less.&lt;br/&gt;&lt;br/&gt;
          Beverage prices -  Wine glass prices must be in line with the price per bottle.

    
        </description>

        <group id="item"  repeater="-1">
          <question id="item_type" name="Item Type">
            <config type="select" />
            <display cols="4" />
              <option name="Food" value="Dish">
                <condition
                  validator="isBlacklisted"
                  target=".stall.site_type"
                  value="'coffee'|'beverage'"
                />
              </option>

              <option name="Beer" value="Beer">
                <condition
                  validator="isBlacklisted"
                  target=".stall.site_type"
                  value="'coffee'|'food'"
                />
              </option>
              <option name="Wine" value="Wine">
                <condition
                  validator="isBlacklisted"
                  target=".stall.site_type"
                  value="'coffee'|'food'"
                />
              </option>
              <option name="Cider" value="Cider">
                <condition
                  validator="isBlacklisted"
                  target=".stall.site_type"
                  value="'coffee'|'food'"
                />
              </option>
              <option name="Spirit" value="Spirit">
                <condition
                  validator="isBlacklisted"
                  target=".stall.site_type"
                  value="'coffee'|'food'"
                />
              </option>
              <option name="Cocktails" value="Cocktails">
                <condition
                  validator="isBlacklisted"
                  target=".stall.site_type"
                  value="'coffee'|'food'"
                />
              </option>
              <option name="Coffee" value="Coffee" />
              <option name="Non Alcoholic" value="Non Alcoholic" />

          </question>

          <question id="item_title" name="Title of beverage">
            <config type="text" />
            <display cols="8" />
            <validator id="isRequired"/>
            <condition
              target=".menu.item#.item_type"
              validator="isBlacklisted"
              value="''|undefined|'Dish'"
              />
          </question>

          <question id="item_title" name="Title of Dish">
            <config type="text" />
            <display cols="8" />
            <validator id="isRequired"/>
            <condition
              target=".menu.item#.item_type"
              validator="isEqual"
              value="Dish"
              />
          </question>

        <group af="food_details"  id="food_details" >
            <display cols="12"/>
            <condition
              target=".menu.item#.item_title"
              validator="isBlacklisted"
              value="''|undefined"
              />
              <condition
              target=".menu.item#.item_type"
              validator="isEqual"
              value="Dish"
              />
              <question id="features" name="Features">
                <config type="check" />
                <display cols="4" />
                <option id="vegetarian" value="Vegetarian" name="Vegetarian" />
                <option id="vegan" value="Vegan" name="Vegan" />
                <option id="gf" value="Gluten Free" name="Gluten Free" />
                <option id="dairyfree" value="Dairy Free" name="Dairy Free" />
                <option id="nut" value="Nut Free" name="Nut Free" />kid friendly  and  
                <option id="kid" value="Kid Friendly " name="Kid Friendly" />
                <option id="taste" value="Taste Plate" name="Taste Plate" />
                <option id="dessert" value="Dessert" name="Dessert" />
                <help_text>When selecting dietary options, you must adhere to strict cross contamination protocols.</help_text>
              </question>

              <!--question id="description" name="Item Description">
                <config type="text" />
                <display cols="8" multiline="4" word_counter="25" />
                <validator id="hasWordLimit">
                  <config limit="25" />
                </validator>
                <help_text>Please provide detail about this product that will be available on your menu. The description you enter will be used and shown on the Festivale website ( so make it sound delicious and tempting to appeal to patrons)</help_text>
              </question-->
            </group>

            <group af="bev_details"  id="bev_details" >
            <display cols="12"/>
            <condition
            target=".menu.item#.item_type"
            validator="isBlacklisted"
            value="''|undefined|'Dish'"
            />
            <condition
              target=".menu.item#.item_title"
              validator="isBlacklisted"
              value="''|undefined"
              />
              
              <description>Beverage Details</description>

              <question id="description" name="Cocktail Ingredients (must be Tasmanian ingredients)">
                <config type="text" />
                <display cols="8" multiline="4" word_counter="25" />
                <condition
                  target=".menu.item#.item_type"
                  validator="isEqual"
                  value="Cocktails"
                  />
                <validator id="hasWordLimit">
                  <config limit="25" />
                </validator>

                <help_text>Please provide detail about this product that will be available on your menu. The description you enter will be used and shown on the Festivale website ( so make it sound delicious and tempting to appeal to patrons)</help_text>
              </question>
            </group>



           

     

          


          
          <group af="food_price" id="food_price">
            <description>Pricing</description>
            <condition
              target=".menu.item#.item_title"
              validator="isBlacklisted"
              value="''|undefined"
            />
            <condition
              target=".menu.item#.item_type"
              validator="isEqual"
              value="Dish"
              />
            <group>
              <question name="Price" id="price_01_food">
                <config type="text" />
                <display cols="4" prefix="$" />
              </question>
              <question name="Serving Type" id="pricename_01_food" help_text="e.g. taste plate, meal for 1, meal for multiple, share platter">
                <config type="select" />
                <display cols="8" />
                <option id="plate" name="Taste plate" value="taste_plate" />
                <option id="platter" name="Share platter" value="share_platter" />
                <option id="other" name="Other" value="other" />
              </question>
              <question name="Other" id="pricename_01_food_other">
                <config type="text" />
                <display cols="8" />
                <condition
                  target=".menu.item#.food_price.pricename_01_food"
                  validator="isEqual"
                  value="other"
                />
              </question>

            </group>
            <group>
            <condition
              target=".menu.item#.food_price.price_01_food"
              validator="isBlacklisted"
              value="''|undefined"
              />
              <question name="Price" id="price_02_food">
                <config type="text" />
                <display cols="4" prefix="$" />
              </question>

              <question name="Serving Type" id="pricename_02_food" help_text="e.g. taste plate, meal for 1, meal for multiple, share platter">
                <config type="select" />
                <display cols="8" />
                <option id="plate" name="taste plate" value="taste_plate" />
                <option id="platter" name="share platter" value="share_platter" />
                <option id="other" name="other" value="other" />
              </question>
              <question name="Other" id="pricename_02_food_other">
                <config type="text" />
                <display cols="8" />
                <condition
                  target=".menu.item#.food_price.pricename_02_food"
                  validator="isEqual"
                  value="other"
                />
              </question>
            </group>
            <group>
            <condition
              target=".menu.item#.food_price.price_02_food"
              validator="isBlacklisted"
              value="''|undefined"
              />
              <question name="Price" id="price_03_food">
                <config type="text" />
                <display cols="4" prefix="$" />
              </question>

              <question name="Serving Type" id="pricename_03_food" help_text="e.g. taste plate, meal for 1, meal for multiple, share platter">
                <config type="select" />
                <display cols="8" />
                <option id="plate" name="taste plate" value="taste_plate" />
                <option id="platter" name="share platter" value="share_platter" />
                <option id="other" name="other" value="other" />
              </question>
              <question name="Other" id="pricename_03_food_other">
                <config type="text" />
                <display cols="8" />
                <condition
                  target=".menu.item#.food_price.pricename_03_food"
                  validator="isEqual"
                  value="other"
                />
              </question>
            </group>
          </group>



          <group af="bev_price" id="bev_price">
          <description> All beverages served by the glass must be reflective  of the bottle price.</description>
          <condition
            target=".menu.item#.item_type"
            validator="isBlacklisted"
            value="''|undefined|'Dish'"
            />
            <condition
              target=".menu.item#.item_title"
              validator="isBlacklisted"
              value="''|undefined"
              />
            <group>
              <question name="Price" id="price_01_drink">
                <config type="text" />
                <display cols="4" prefix="$" />
              </question>
              <question name="Serving Type" id="pricename_01_drink" help_text="e.g. Glass, bottle, tap, etc...">
                <config type="select" />
                <display cols="8" />
                <option id="glass" name="Glass" value="glass" />
                <option id="bottle" name="Bottle" value="bottle" />
                <option id="cup" name="Cup" value="cup" />
                <option id="mug" name="Mug" value="mug" />
              </question>
              <question name="Other" id="pricename_01_drink_other">
                <config type="text" />
                <display cols="8" />
                <condition
                  target=".menu.item#.bev_price.pricename_01_drink"
                  validator="isEqual"
                  value="other"
                />
              </question>
            </group>
            <group>
            <condition
              target=".menu.item#.bev_price.price_01_drink"
              validator="isBlacklisted"
              value="''|undefined"
              />
              <question name="Price" id="price_02_drink">
                <config type="text" />
                <display cols="4" prefix="$" />
              </question>

              <question name="Serving Type" id="pricename_02_drink" help_text="e.g. Glass, bottle, tap, etc...">
                <config type="select" />
                <display cols="8" />
                <option id="glass" name="Glass" value="glass" />
                <option id="bottle" name="Bottle" value="bottle" />
                <option id="bottle" name="Cup" value="cup" />
                <option id="bottle" name="Mug" value="mug" />
              </question>
              <question name="Other" id="pricename_02_drink_other">
                <config type="text" />
                <display cols="8" />
                <condition
                  target=".menu.item#.bev_price.pricename_02_drink"
                  validator="isEqual"
                  value="other"
                />
              </question>
            </group>
            <group>
            <condition
              target=".menu.item#.bev_price.price_02_drink"
              validator="isBlacklisted"
              value="''|undefined"
              />
              <question name="Price" id="price_03_drink">
                <config type="text" />
                <display cols="4" prefix="$" />
              </question>

              <question name="Serving Type" id="pricename_03_drink" help_text="e.g. Glass, bottle, tap, etc...">
                <config type="select" />
                <display cols="8" />
                <option id="glass" name="Glass" value="glass" />
                <option id="bottle" name="Bottle" value="bottle" />
                <option id="bottle" name="Cup" value="cup" />
                <option id="bottle" name="Mug" value="mug" />
              </question>
              <question name="Other" id="pricename_03_drink_other">
                <config type="text" />
                <display cols="8" />
                <condition
                  target=".menu.item#.bev_price.pricename_03_drink"
                  validator="isEqual"
                  value="other"
                />
              </question>
            </group>
          </group>

          <group id="note">
            <description>Please click ADD MENU ITEM for all additional items </description>
          </group>

        </group>
      </group>
    </step>

    <step id="finalise" name="Finalise">

      <group name="Checklist" layout="Checklist">
        <question name="Full name and contact details filled in" id="name" targetNode="personal">
          <config type="checklist" />
          <validator id="isComplete" />
        </question>

        <question name="Site Layout Map completed and attached" id="layout" targetNode="plan">
          <config type="checklist" />
          <validator id="isComplete" />
        </question>

        <question name="Electrical requirements completed" id="electric" targetNode="power">
          <config type="checklist" />
          <validator id="isComplete" />
        </question>

        <question name="Images of site" id="images" targetNode="photo_01">
          <config type="checklist" />
          <validator id="isComplete" />
        </question>

        <question name="All questions on STALL page completed" id="stallcl" targetNode="stall">
          <config type="checklist" />
          <validator id="isComplete" />
        </question>

        <question name="All questions on PERSONAL page completed" id="personalcl" targetNode="personal">
          <config type="checklist" />
          <validator id="isComplete" />
        </question>

        <question name="All questions on SITE page completed" id="sitecl" targetNode="site">
          <config type="checklist" />
          <validator id="isComplete" />
        </question>

        <question name="All questions on BUSINESS page completed" id="businesscl" targetNode="business">
          <config type="checklist" />
          <validator id="isComplete" />
        </question>


        <question name="All questions on MENU page completed" id="menucl" targetNode="menu">
          <config type="checklist" />
          <validator id="isComplete" />
        </question>


      </group>

      <group name="Costs Relating to your Site at Festivale 2025" layout="Estimate">
        <description>Your site fee includes, a marquee to the size that you have requested within this application, 1 power outlet and, 2x 3 day stallholder passes. All sites, regardless of location, will be charged a set fee according to the size of the site area required not the size of marquee or van.</description>
        
        <question name="Cost Adjustment Amount" id="cost_adjust" >
          <config type="text" />
        </question>

        <question name="Cost Adjustment Reason" id="cost_adjust_reason" >
          <config type="text" />
        </question>

      
      </group>


      <group name="Finalise your application">
        <question name="I have read and understand the Terms &amp; Conditions of Festivale and Stallholder Application detail." id="tnc23">
          <config type="radio"/>
          <display cols="12" />
          <option id="yes" name="Yes" value="yes" description="" />
          <validator id="isRequired">
            <config message="In order to submit, you need to tick to confirm you agree"/>
          </validator>
          <help_text>&lt;a href='/Stallholder Application TC Final_web version 2025_V1.pdf' target='blank' &gt; View Terms &amp; Conditions of Festivale and Stallholder Application Detail here &lt;/a&gt;</help_text>
        </question>

        <question name="I authorise the Launceston City Council Environmental Health Offices to release any information to the Festivale Committee relating to my temporary food licence application and audit." id="audit">
          <config type="radio"/>
          <display cols="12" />
          <option id="yes" name="Yes" value="yes" />
          <validator id="isRequired">
            <config message="In order to submit, you need to tick to confirm you agree"/>
          </validator>
        </question>


      </group>

      <group name="Submit your application">
        <description>By filling in the information below and submitting you confirm that your application has been checked in detail and all content (including the size of site selected,  requirements and all menu detail entered has been checked for spelling / grammatical errors).  &lt;br /&gt;&lt;br /&gt;

        Once you submit, your application will be flagged as “complete” and ready for review by the Festivale Committee.&lt;br /&gt;&lt;br /&gt;

        Thank you very much and we look forward to reviewing your application.
        </description>

        <question name="I confirm that I am authorised to complete this application on behalf of the business" id="authorised">
          <config type="radio"/>
          <display cols="12" />
          <option id="yes" name="Yes" value="yes" />
          <validator id="isRequired">
            <config message="In order to submit, you need to tick to confirm you agree"/>
          </validator>
        </question>

        <question id="full_name" name="Your Full Name">
          <config type="text" />
          <validator id="isRequired" />
        </question>

        <question id="full_position" name="Your Position">
          <config type="text" />
          <validator id="isRequired" />
        </question>


        

        <question id="finalSubmit" name="submit and lock application">
          <display cols="12" />
          <config type="button" function="finalSubmit();" />
        </question>

      </group>

    </step>


     <step id="extra" name="Extra">
  <group name="Upload Files">
    <description>Successful stallholder application documents required</description>

    <question id="pli" name="Public Liability Insurance">
      <help_text>Please upload your Public Liability Insurance Certificate of Currency with cover of at least $10 million. </help_text>
      <config type="file" />
    </question>

    <question id="pli_date" name="Public Liability Insurance Expiration Date">
      <config type="date" />
    </question>

     <question id="tfbp" name="Temporary Food Business Permit">
      <config type="file" />
    </question>

    <question id="ssp" name="Site Safety Plans ">
      <config type="file" />
    </question>

    <question id="logo" name="The logo you provide will be used for marketing purposes including your listing on the website and Festivale APP.  ">
      <config type="file" />
    </question>

    <group>
      <description>It is a requirement by Festivale that ALL staff serving alcohol at the event have undertaken the Responsible Serving of Alcohol Course. Staff working at the event will be required to provide a copy of their RSA's to the Festivale office prior to bump in. </description>
      
        <question id="rsi_file_0" name="Responsible Service of Alcohol">
          <config type="file" />
        </question>

        <question id="rsi_file_1" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_0"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_2" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_1"
            validator="isNotEqual"
            value="undefined"
          />
        </question>
      
        <question id="rsi_file_3" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_2"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_4" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_3"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_5" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_4"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_6" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_5"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_7" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_6"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_8" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_7"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_9" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_8"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_10" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_9"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_11" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_10"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_12" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_11"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_13" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_12"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_14" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_13"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_15" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_14"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_16" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_15"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_17" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_16"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_18" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_17"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_19" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_18"
            validator="isNotEqual"
            value="undefined"
          />
        </question>

        <question id="rsi_file_20" name="Responsible Service of Alcohol">
          <config type="file" />
          <condition
            target=".extra.rsi_file_19"
            validator="isNotEqual"
            value="undefined"
          />
        </question>
      
         

    </group>

    </group>
    </step>


</app>
`
export default xmlData
